import React, { useState, useEffect, useCallback } from "react";
import { StarIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
} from "@chakra-ui/react";
import { secureHttp, getToken, setAuthToken } from "../services/http";
import { useLocation } from "react-router-dom";

interface PlayerRating {
  id: number;
  nomeAtleta: string;
  apelido: string;
  idade: number;
  telAtleta: string;
  bloco: string;
  apto: string;
  inVisitante: string;
  urlImagem: string;
  ativo: boolean;
}

const Profile = () => {
  const location = useLocation();
  const [ratings, setRatings] = useState<number>(0);
  const [votedPlayers, setVotedPlayers] = useState<number[]>([]);
  const userId = location.state?.userId;
  const [currentPlayer, setCurrentPlayer] = useState<PlayerRating | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const fetchNextPlayer = useCallback(async () => {
    try {
      const token = await getToken(); 
      setAuthToken(token); 

      const response = await secureHttp.get(
        `avaliacoes-atletas-torneio/nao-votados/${userId}`
      );
      if (response.data && Array.isArray(response.data.conteudo)) {
        const validPlayers = response.data.conteudo.filter(
          (player: PlayerRating) => !votedPlayers.includes(player.id)
        );
        if (validPlayers.length > 0) {
          setCurrentPlayer(validPlayers[0]);
        } else {
          setCurrentPlayer(null);
        }
      } else {
        console.error("Dados de jogadores inválidos:", response.data);
      }
    } catch (error) {
      console.error("Erro ao buscar jogadores:", error);
    } finally {
      setLoading(false);
    }
  }, [userId, votedPlayers]);

  useEffect(() => {
    fetchNextPlayer();
  }, [fetchNextPlayer, votedPlayers]);

  const handleVote = async () => {
    if (ratings === 0 || !currentPlayer) {
      alert("Por favor, faça uma avaliação.");
      return;
    }

    try {
      const response = await secureHttp.post("/avaliacoes-atletas-torneio", {
        idAvaliado: currentPlayer.id,
        idAvaliador: userId,
        estrelas: ratings,
      });

      if (response.status === 200) {
        setVotedPlayers([...votedPlayers, currentPlayer.id]);
        setRatings(0);
        fetchNextPlayer();
      } else {
        console.error("Erro ao enviar a avaliação.");
      }
    } catch (error) {
      console.error("Erro ao enviar a avaliação:", error);
    }
  };

  const handleStarClick = (index: number) => {
    setRatings(index);
  };

  const handleImageClick = () => {
    setIsImageModalOpen(true);
  };

  if (loading) {
    return <Box>Carregando...</Box>;
  }

  if (!currentPlayer) {
    return (
      <Box
        bg="white"
        mx="auto"
        borderRadius="10px"
        h="100px"
        mt={10}
        maxW={{ base: "92%", sm: "70%" }}
      >
        <Heading
          p={{ base: 5, sm: 5, md: "5%" }}
          size="md"
          textAlign="center"
          fontWeight="bold"
        >
          Obrigado pela sua votação! Todos jogadores votados.
        </Heading>
      </Box>
    );
  }

  return (
    <Box
      bg="white"
      borderRadius="md"
      boxShadow="md"
      maxW={{ base: "90%", sm: "70%", md: "lg" }}
      mx="auto"
      h="100%"
      mt={{ base: 10, md: 20 }}
      p={{ base: 6, md: 8 }}
      w="100%"
    >
      <Box textAlign="center">
        <Heading fontSize={{ base: "2xl", md: "3xl" }} mb={6}>
          Avaliação
        </Heading>
        <Avatar
          src={currentPlayer.urlImagem}
          borderRadius="full"
          boxSize="150px"
          onClick={handleImageClick}
          cursor="pointer"
        />
        <Heading as="h1" size="md" mb={4} mt={4}>
          Atleta: {currentPlayer.nomeAtleta}
        </Heading>
        <Flex justify="center" mb={4}>
          {[1, 2, 3, 4, 5].map((index) => (
            <IconButton
              key={index}
              aria-label={`star-${index}`}
              icon={<StarIcon boxSize={10} />}
              bg="transparent"
              colorScheme="transparent"
              color={index <= ratings ? "#FBBC04" : "gray.200"}
              onClick={() => handleStarClick(index)}
              marginRight={4}
              mb={5}
            />
          ))}
        </Flex>
        <Button w="100%" colorScheme="green" onClick={handleVote}>
          Próximo Jogador
        </Button>
      </Box>

      <Modal isOpen={isImageModalOpen} onClose={() => setIsImageModalOpen(false)}>
        <ModalOverlay />
        <ModalContent maxW="800px">
          <ModalHeader textAlign="center">Imagem do Atleta</ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" justifyContent="center">
            <Image src={currentPlayer.urlImagem} maxW="100%" maxH="500px" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => setIsImageModalOpen(false)}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Profile;
