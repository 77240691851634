import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { MainLayout } from "../../layouts/main";
import AdmPage from "../../pages/adm";
import Artilharia from "../../pages/artillery";
import { Avaliation } from "../../pages/avaliation";
import ClassificacoesJogo from "../../pages/classification";
import TabelaJogos from "../../pages/games";
import { Home } from "../../pages/home";
import Payment from "../../pages/payment";
import Profile from "../../pages/profiles";
import Register from "../../pages/register";
import SorteioPage from "../../pages/sorteio";
import Mesa from "../../pages/summary";
import { Voting } from "../../pages/voting";
import ProtectedRoute from "./protectedRoute";

const Unauthenticated = () => {
  const [isPaymentIdValid, setIsPaymentIdValid] = useState(false);

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/avaliation"
          element={<Avaliation setIsPaymentIdValid={setIsPaymentIdValid} />}
        />
        <Route path="/profile" element={<Profile />} />
        <Route element={<ProtectedRoute condition={isPaymentIdValid} />}>
          <Route path="/payment" element={<Payment />} />
          <Route element={<AdmPage />} path="/adm" />
          <Route element={<SorteioPage />} path="/sorteio" />
        </Route>{" "}
        <Route path="/voting" element={<Voting />} />
        <Route path="/classification" element={<ClassificacoesJogo />} />
        <Route path="/games" element={<TabelaJogos />} />
        <Route path="/artillery" element={<Artilharia />} />
        <Route path="/summary" element={<Mesa />} />
      </Route>
    </Routes>
  );
};

export default Unauthenticated;
