import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Authenticated from "./routers/authenticated";
import Unauthenticated from "./routers/unauthenticated";

export function RoutesApp() {
  return (
    <Routes>
      <Route path="/*" element={<Unauthenticated />} />
    </Routes>
  );
}
