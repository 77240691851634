import { Flex, Image, useBreakpointValue } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import logo from "../../../assets/olympique.jpeg";

interface IProps {
  onOpen: () => void;
}

export function HeaderSign({ onOpen }: IProps) {
  const imageSize = useBreakpointValue({
    base: "100px",
    md: "100px",
    lg: "80px",
  });

  return (
    <Flex
      boxShadow="lg"
      width="full"
      height="20"
      paddingInline="5"
      backgroundColor="#000000"
      alignItems="center"
      justifyContent="center"
    >
      <Link to="/">
        {" "}
        <Image mt={5}  src={logo} alt="Logo" w={imageSize} mb={3} />
      </Link>
    </Flex>
  );
}
