import React, { useState, useEffect } from 'react';
import {
  Table as ChakraUITable,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  Heading,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Image,
  Box,
} from "@chakra-ui/react";
import { httpEuro } from '../services/euro/http';

interface Gol {
  idAtleta: number;
  quantidade: number;
}

interface Atleta {
  nomeAtleta: string;
  documento: string;
  idTimes: number;
}

function Artilharia() {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<(Gol & Atleta)[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await httpEuro.get('euro/api/gols/gols-campeonatos-artilharia/2');
        const artilhariaData: Gol[] = response.data.conteudo;

        const atletasPromises = artilhariaData.map(async (item: Gol) => {
          const atletaResponse = await httpEuro.get(`/euro/api/atletas/${item.idAtleta}`);
          const atleta: Atleta = atletaResponse.data.conteudo;

          return {
            ...item,
            nomeAtleta: atleta.nomeAtleta,
            documento: atleta.documento,
            idTimes: atleta.idTimes
          };
        });

        const atletasData = await Promise.all(atletasPromises);
        setData(atletasData);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getColorFromTeamId = (idTimes: number): { color: string; shadow: string } => {
    switch (idTimes) {
      case 270:
        return { color: "#0000FF", shadow: "none" }; // Azul
      case 271:
        return { color: "#FF0000", shadow: "none" }; // Vermelho
      case 272:
        return { color: "#008000", shadow: "none" }; // Verde
      case 273:
        return { color: "#FFFFFF", shadow: "0 0 2px 1px rgba(0, 0, 0, 0.5)" }; // Branco
      case 274:
        return { color: "#FFFF00", shadow: "none" }; // Amarelo
      default:
        return { color: "#000000", shadow: "none" }; // Desconhecido
    }
  };

  const formatFirstName = (nome: string): string => {
    const firstName = nome.split(" ")[0];
    return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
  };

  return (
    <Flex
      color="black"
      flex="1"
      flexDirection="column"
      alignItems="center"
      overflowX="auto"
      marginX="auto"
      maxHeight="calc(100vh - 14rem)"
      overflowY="auto"
      paddingLeft={2}
    >
      <Heading
        fontSize={["xl", "2xl", "3xl"]}
        mt={["5%", "3%", "2%"]}
        mb={["5%", "3%", "2%"]}
      >
        Artilharia 
      </Heading>
      <TableContainer
        overflowX="auto"
        marginX="auto"
        maxHeight="calc(100vh - 14rem)"
        overflowY="auto"
      >
        <ChakraUITable>
          <Thead>
            <Tr>
              <Th textAlign="center"></Th>
              <Th textAlign="center">Atleta</Th>
              <Th textAlign="center">Nome</Th>
              <Th textAlign="center">Equipe</Th>
              <Th>Gols</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <Tr>
                <Td colSpan={5} textAlign="center">
                  Carregando...
                </Td>
              </Tr>
            ) : (
              data.map((item, index) => {
                const { color, shadow } = getColorFromTeamId(item.idTimes);

                return (
                  <Tr key={index}>
                    <Td
                      color={"#fff"}
                      textAlign="center"
                      bgColor="black"
                      style={{ padding: "1px", boxShadow: shadow }}
                    >
                      {index + 1}&#176;
                    </Td>
                    <Td>
                      <Flex alignItems="center">
                        <Avatar
                          src={item.documento}
                          name="Avatar do Atleta"
                          boxSize={["30px", "40px"]}
                          mr="10px"
                          onClick={() => setSelectedImage(item.documento)}
                          cursor="pointer"
                        />
                      </Flex>
                    </Td>
                    <Td>
                      <Text>{formatFirstName(item.nomeAtleta)}</Text>
                    </Td>
                    <Td>
                      <Flex alignItems="center">
                        <Box
                          boxSize={["35px", "45px"]}
                          mr="10px"
                          bg={color}
                          border="1px solid black"
                          borderRadius="full"
                        />
                      </Flex>
                    </Td>
                    <Td textAlign="center">{item.quantidade}</Td>
                  </Tr>
                );
              })
            )}
          </Tbody>
        </ChakraUITable>
      </TableContainer>{" "}
      <Modal isOpen={!!selectedImage} onClose={() => setSelectedImage(undefined)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Imagem do Atleta</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image src={selectedImage} alt="Imagem do Atleta" />
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setSelectedImage(undefined)}>Fechar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default Artilharia;
