import React from "react";
import { Box, VStack, Heading, Button, Container } from "@chakra-ui/react";
import {
  FaTrophy,
  FaUsers,
  FaCalendarAlt,
  FaCreditCard,
  FaCoins,
  FaFileInvoiceDollar,
} from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import img1 from "../assets/a2.jpeg";

const AdmPage: React.FC = () => {
  return (
    <Box 
      bgImage={`url(${img1})`}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      flex="1"
    >         <Heading mt="5%"  textAlign="center" color="white">Painel Administrativo</Heading>

      <VStack spacing={8} align="center"   h="100%" p={10}>

        <Container maxW="300px">
          <NavLink to="/sorteio">
            <Button
              leftIcon={<FaCreditCard />}
              w="full"
              colorScheme="white"
              variant="outline"
              color="green"
              backgroundColor="whiteAlpha.800"
            >
              Sorteio
            </Button>
          </NavLink>
        </Container>
        <Container maxW="300px">
          <NavLink to="/payment">
            <Button
              leftIcon={<FaCoins />}
              w="full"
              colorScheme="white"
              variant="outline"
              color="green"
              backgroundColor="whiteAlpha.800"
            >
              Financeiro
            </Button>
          </NavLink>
        </Container>
        <Container maxW="300px">
          <NavLink to="/despesas">
            <Button
              leftIcon={<FaFileInvoiceDollar />}
              w="full"
              colorScheme="white"
              variant="outline"
              color="green"
              backgroundColor="whiteAlpha.800"
            >
              Despesas
            </Button>
          </NavLink>
        </Container>
      </VStack>
    </Box>
  );
};

export default AdmPage;
