import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Text,
  Avatar,
  Switch,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Image,
} from "@chakra-ui/react";
import { http } from "../services/http";
import { useNavigate } from "react-router-dom";

interface Athlete {
  id: number;
  nomeAtleta: string;
  urlImagem: string;
  pago: boolean;
}

const Payment = () => {
  const [athletes, setAthletes] = useState<Athlete[]>([]);
  const [selectedAthlete, setSelectedAthlete] = useState<Athlete | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const toast = useToast();

  useEffect(() => {
    async function fetchAthletes() {
      try {
        const response = await http.get("/inscricao/listarTodos");
        const fetchedAthletes = response.data.conteudo;

        const updatedAthletes = await Promise.all(
          fetchedAthletes.map(async (athlete: Athlete) => {
            try {
              const paymentStatusResponse = await http.get(
                `/pagamento/listarTodosTime/${athlete.id}`
              );
              athlete.pago = paymentStatusResponse.data.codigoHTTP === 200;
            } catch (error) {
              console.error(
                `Error fetching payment status for athlete ${athlete.id}:`,
                error
              );
              athlete.pago = false;
            }
            return athlete;
          })
        );

        setAthletes(updatedAthletes);
      } catch (error) {
        console.error("Error fetching athletes:", error);
      }
    }

    fetchAthletes();
  }, []);

  const handlePaymentStatusChange = (athlete: Athlete) => {
    if (athlete.pago) {
      toast({
        title: "Pagamento já realizado",
        description: "Este atleta já está marcado como pago.",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    } else {
      setSelectedAthlete(athlete);
      setIsModalOpen(true);
    }
  };

  const confirmPayment = async () => {
    if (selectedAthlete) {
      try {
        const payload = {
          idCamp: 1,
          idAtleta: selectedAthlete.id,
          idTime: 1,
          numParcela: 1,
          dataPgtoParcela: new Date().toISOString(),
          valParcela: 80,
        };

        const response = await http.post("/pagamento", payload);

        if (response.data.codigoHTTP === 200) {
          setAthletes((prevAthletes) =>
            prevAthletes.map((athlete) =>
              athlete.id === selectedAthlete.id
                ? { ...athlete, pago: true }
                : athlete
            )
          );
          toast({
            title: "Pagamento realizado",
            description: "O pagamento foi confirmado com sucesso.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          throw new Error("Erro ao processar pagamento");
        }
      } catch (error) {
        console.error("Error processing payment:", error);
        toast({
          title: "Erro",
          description: "Não foi possível confirmar o pagamento.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsModalOpen(false);
        setSelectedAthlete(null);
      }
    }
  };

  const handleImageClick = (url: string) => {
    setImageUrl(url);
    setIsImageModalOpen(true);
  };

  return (
    <Flex justifyContent="center" flex="1">
      <Flex flexWrap="wrap" mt="5%">
        {athletes.map((athlete) => (
          <Box
            key={athlete.id}
            padding="4"
            width="250px"
            height="300px"
            mx="auto"
          >
            <Box
              textAlign="center"
              bg="white"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              height="100%"
            >
              <Avatar
                src={athlete.urlImagem}
                borderRadius="full"
                boxSize="120px"
                style={{ margin: "auto", marginTop: "5%" }}
                onClick={() => handleImageClick(athlete.urlImagem)}
                cursor="pointer"
              />
              <Box p="4">
                <Text fontWeight="semibold" fontSize="lg">
                  {athlete.nomeAtleta}
                </Text>
                <Text fontWeight="semibold" fontSize="lg">
                  {athlete.pago ? "Pago" : "Não Pago"}
                </Text>
                <Switch
                  colorScheme="green"
                  size="sm"
                  isChecked={athlete.pago}
                  onChange={() => handlePaymentStatusChange(athlete)}
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Flex>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmar Pagamento</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Tem certeza que deseja marcar como pago?</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => setIsModalOpen(false)}
            >
              Cancelar
            </Button>
            <Button colorScheme="green" onClick={confirmPayment}>
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent maxW="800px">
          <ModalHeader textAlign="center">Imagem do Atleta</ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" justifyContent="center">
            <Image src={imageUrl} maxW="100%" maxH="500px" />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={() => setIsImageModalOpen(false)}
            >
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Payment;
