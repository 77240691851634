import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { Footer } from "../main/components/footer";
import { HeaderSign } from "./components/header";

export function MainLayout() {
  const { onOpen } = useDisclosure();

  return (
    <Box bg="#EFEFEF">
      <HeaderSign onOpen={onOpen} />
      <Flex width="full" minHeight="calc(100vh - 112px)">
        <Outlet />
      </Flex>
      <Footer />
    </Box>
  );
}
