import React, { useState, useEffect } from "react";
import { Box, Flex, VStack, Text, Heading } from "@chakra-ui/react";
import {
  MdEventAvailable,
  MdLeaderboard,
  MdSportsSoccer,
} from "react-icons/md";
import { FaChartLine } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const navigate = useNavigate();
  const [timeRemaining, setTimeRemaining] = useState("");
  const [totalInscricoes, setTotalInscricoes] = useState<number>(0);

  const navigateToInscricao = () => {
    navigate("/classification");
  };

  const navigateToVotar = () => {
    navigate("/games");
  };

  const navigateToVisualizar = () => {
    navigate("/artillery");
  };

  const navigateToSumula = () => {
    navigate("/summary");
  };

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const deadline = new Date("2024-05-24T00:00:00").getTime();
      const now = new Date().getTime();
      const difference = deadline - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      } else {
        setTimeRemaining("Encerrado");
      }
    };

    calculateTimeRemaining();

    const interval = setInterval(calculateTimeRemaining, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchTotalInscricoes = async () => {
      try {
        const response = await fetch(
          "https://api.torneio-olympique.com.br/euro/api/inscricao/contarTotal"
        );
        const data = await response.json();
        setTotalInscricoes(data.conteudo);
      } catch (error) {
        console.error("Erro ao buscar total de inscrições:", error);
      }
    };

    fetchTotalInscricoes();
  }, []);

  return (
    <Flex justify="center" mt="5%" flex="1">
      <VStack spacing={8} align="center">
        <Heading fontSize={["2xl", "3xl", "4xl", "5xl"]} textAlign="center">
          Bem-vindo ao torneio Olympique
        </Heading>
        <Flex justify="center" align="center" flexWrap="wrap" gap={8}>
          <Box
            w={["90%", "45%", "100%"]}
            maxW="250px"
            h="200px"
            bgGradient="linear(to-br, green.300, green.500)"
            borderRadius="lg"
            boxShadow="lg"
            p={6}
            textAlign="center"
            cursor="pointer"
            onClick={navigateToInscricao}
            _hover={{ transform: "scale(1.05)" }}
            transition="transform 0.3s ease"
            marginTop={3}
          >
            <Flex justify="center" align="center">
              <MdEventAvailable size={48} color="white" />
            </Flex>
            <Text
              mt={4}
              fontWeight="bold"
              fontSize={["xl", "2xl", "3xl"]}
              color="white"
            >
              Classificação
            </Text>
          </Box>
          <Box
            w={["90%", "45%", "100%"]}
            maxW="250px"
            h="200px"
            bgGradient="linear(to-br, #ff9966, #ff5e62)"
            borderRadius="lg"
            boxShadow="lg"
            p={6}
            textAlign="center"
            cursor="pointer"
            onClick={navigateToVotar}
            _hover={{ transform: "scale(1.05)" }}
            transition="transform 0.3s ease"
          >
            <Flex justify="center" align="center">
              <MdLeaderboard size={48} color="white" />
            </Flex>
            <Text
              mt={4}
              fontWeight="bold"
              fontSize={["xl", "2xl", "3xl"]}
              color="white"
            >
              Jogos
            </Text>
          </Box>
          <Box
            w={["90%", "45%", "100%"]}
            maxW="250px"
            h="200px"
            bgGradient="linear(to-br, #56CCF2, #2F80ED)"
            borderRadius="lg"
            boxShadow="lg"
            p={6}
            textAlign="center"
            cursor="pointer"
            onClick={navigateToVisualizar}
            _hover={{ transform: "scale(1.05)" }}
            transition="transform 0.3s ease"
            marginBottom={5}
          >
            <Flex justify="center" align="center">
              <FaChartLine size={48} color="white" />
            </Flex>
            <Text
              mt={4}
              fontWeight="bold"
              fontSize={["xl", "2xl", "3xl"]}
              color="white"
            >
              Artilharia
            </Text>
          </Box>
          {/* <Box
            w={["90%", "45%", "100%"]}
            maxW="250px"
            h="200px"
            bgGradient="linear(to-br, #B06AB3, #4568DC)"
            borderRadius="lg"
            boxShadow="lg"
            p={6}
            textAlign="center"
            cursor="pointer"
            onClick={navigateToSumula}
            _hover={{ transform: "scale(1.05)" }}
            transition="transform 0.3s ease"
          >
            <Flex justify="center" align="center">
              <MdSportsSoccer size={48} color="white" />
            </Flex>
            <Text
              mt={4}
              fontWeight="bold"
              fontSize={["xl", "2xl", "3xl"]}
              color="white"
            >
              Súmula
            </Text>
          </Box>   */}
        </Flex>
      </VStack>
    </Flex>
  );
};
