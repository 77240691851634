import { useState, ChangeEvent, FormEvent } from "react";
import {
  Checkbox,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  Image as ChakraImage,
  HStack,
  Heading,
  IconButton,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import CustomModal from "../components/modalAlert";
import { http } from "../services/http";
import { useNavigate } from "react-router-dom";

const MAX_WIDTH = 800;
const MAX_HEIGHT = 800;

const resizeImage = (
  file: File,
  maxWidth: number,
  maxHeight: number
): Promise<File> => {
  return new Promise((resolve, reject) => {
    const img = document.createElement("img") as HTMLImageElement;
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height *= maxWidth / width));
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width *= maxHeight / height));
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      if (ctx) {
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          if (blob) {
            const resizedFile = new File([blob], file.name, {
              type: file.type,
            });
            resolve(resizedFile);
          } else {
            reject(new Error("Failed to resize image"));
          }
        }, file.type);
      } else {
        reject(new Error("Failed to get canvas context"));
      }
    };
    img.onerror = () => {
      reject(new Error("Failed to load image"));
    };
  });
};

const Register = () => {
  const [tipoCadastro, setTipoCadastro] = useState<string>("");
  const [nome, setNome] = useState<string>("");
  const [apelido, setApelido] = useState<string>("");
  const [apto, setApto] = useState<string>("");
  const [bloco, setBloco] = useState<string>("");
  const [isConvidado, setIsConvidado] = useState<boolean>(false);
  const [idade, setIdade] = useState<number>(0);
  const [telefone, setTelefone] = useState<string>("");
  const [foto, setFoto] = useState<File | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");

  const navigate = useNavigate();

  const handleTipoCadastroChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIsConvidado(checked);
    setTipoCadastro(checked ? "convidado" : "");
  };

  const handleFotoChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const resizedFile = await resizeImage(file, MAX_WIDTH, MAX_HEIGHT);
        setFoto(resizedFile);
      } catch (error) {
        console.error("Erro ao redimensionar a imagem:", error);
      }
    }
  };

  const handleRemoveFoto = () => {
    setFoto(null);
  };

  const generateRandomNumber = () => {
    return Math.floor(100 + Math.random() * 900);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!nome || !apelido || !idade || !telefone || !apto || !bloco || !foto) {
      alert("Por favor, preencha todos os campos");
      return;
    }

    if (idade > 60) {
      alert(
        "Desculpe, só aceitamos inscrições para pessoas com até 60 anos de idade."
      );
      return;
    }

    try {
      const inVisitante = tipoCadastro === "convidado" ? "S" : "N";

      const inscricaoResponse = await http.post("/inscricao", {
        nomeAtleta: nome,
        apelido: apelido,
        idade: idade,
        telAtleta: telefone,
        bloco: bloco,
        apto: apto,
        inVisitante: inVisitante,
        urlImagem: "foto",
        ativo: true,
      });

      if (inscricaoResponse.status === 200) {
        const idInscricao = inscricaoResponse.data.conteudo.id;

        const formData = new FormData();
        formData.append("tipoArquivo", "1");
        formData.append("nomeAtleta", nome);
        formData.append("arquivo", foto);
        formData.append("idInscricao", idInscricao.toString());

        const uploadResponse = await fetch(
          "https://api.torneio-olympique.com.br/euro/api/armazenamento-arquivo",
          //  "http://localhost:8080/euro/api/armazenamento-arquivo",
          {
            method: "POST",
            body: formData,
          }
        );

        const uploadData = await uploadResponse.json();

        if (uploadResponse.ok && uploadData.codigoHTTP === 200) {
          const documento = `${generateRandomNumber()}${idInscricao}`;

          const atletaResponse = await http.post("/atletas", {
            idTimes: idInscricao,
            nomeAtleta: nome,
            documento: documento,
            dataNasc: "",
            telAtleta: "",
            posicaoAtleta: "",
            imagem: "",
          });

          if (atletaResponse.status === 200) {
            setShowModal(true);
            setToken(documento);
          }
        } else {
          throw new Error("Falha no upload da imagem");
        }
      }
    } catch (error) {
      console.error("Erro ao enviar inscrição:", error);
      alert("Erro ao enviar inscrição. Por favor, tente novamente mais tarde.");
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Box
      mb={10}
      bg="white"
      borderRadius="md"
      boxShadow="md"
      maxW={{ base: "90%", sm: "70%", md: "lg" }}
      mx="auto"
      mt={{ base: 10, md: 10 }}
      p={{ base: 6, md: 8 }}
      w="100%"
    >
      <Heading fontSize={{ base: "2xl", md: "3xl" }} textAlign="center" mb={6}>
        Inscrição
      </Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={6} align="stretch">
          <FormControl isRequired>
            <FormLabel htmlFor="nome">Nome</FormLabel>
            <Input
              id="nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              placeholder="Nome"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="apelido">Apelido</FormLabel>
            <Input
              id="apelido"
              value={apelido}
              onChange={(e) => setApelido(e.target.value)}
              placeholder="Apelido"
            />
          </FormControl>
          <HStack>
            <FormControl isRequired>
              <FormLabel htmlFor="idade">Idade</FormLabel>
              <Input
                id="idade"
                type="number"
                value={idade}
                onChange={(e) => setIdade(parseInt(e.target.value))}
                placeholder="Idade"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="telefone">Telefone</FormLabel>
              <Input
                type="tel"
                id="telefone"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
                placeholder="Telefone"
              />
            </FormControl>
          </HStack>
          <HStack>
            <FormControl isRequired>
              <FormLabel htmlFor="apartamento">Apartamento</FormLabel>
              <Input
                id="apartamento"
                value={apto}
                onChange={(e) => setApto(e.target.value)}
                placeholder="Apartamento"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="bloco">Bloco</FormLabel>
              <Input
                id="bloco"
                value={bloco}
                onChange={(e) => setBloco(e.target.value)}
                placeholder="Bloco"
              />
            </FormControl>
          </HStack>
          <HStack spacing={4}>
            <Checkbox
              size="lg"
              colorScheme="green"
              isChecked={isConvidado}
              onChange={handleTipoCadastroChange}
              value="convidado"
            >
              Convidado
            </Checkbox>
          </HStack>
          <FormControl isRequired>
            <FormLabel htmlFor="foto">Foto</FormLabel>
            <input
              type="file"
              accept="image/*"
              onChange={handleFotoChange}
              style={{ display: "none" }}
              id="fileInput"
            />
            {foto ? (
              <Box>
                <ChakraImage
                  src={URL.createObjectURL(foto)}
                  alt="Foto"
                  maxH="100px"
                  mb={3}
                />
                <IconButton
                  bg="transparent"
                  aria-label="Remover foto"
                  icon={<CloseIcon />}
                  position="absolute"
                  top="0"
                  right="210"
                  onClick={handleRemoveFoto}
                />
              </Box>
            ) : (
              <Button
                color="white"
                bg="#26A169"
                onClick={() => {
                  const fileInput = document.getElementById("fileInput");
                  if (fileInput) {
                    fileInput.click();
                  }
                }}
              >
                Adicionar Imagem
              </Button>
            )}
          </FormControl>
          <Button
            type="submit"
            w="100%"
            bg="#00616A"
            color="white"
            colorScheme="green"
          >
            Enviar
          </Button>
        </VStack>
      </form>

      {showModal && (
        <CustomModal
          isOpen={showModal}
          onClose={handleCloseModal}
          message={`Cadastro concluído com sucesso! Seu token é: ${token}. Por favor, guarde este token, pois ele será necessário para avaliação.`}
        />
      )}
    </Box>
  );
};

export default Register;
