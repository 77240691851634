import { useState, useEffect } from "react";
import {
  Table as ChakraUITable,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  Heading,
  Avatar,
} from "@chakra-ui/react";
import { http } from "../services/http";
import axios from "axios";
import { httpEuro } from "../services/euro/http";

interface Classificacao {
  timesResponse: {
    imagem: string;
    nomeTime: string;
  };
  qtdJogos: number;
  pontos: number;
  vitoria: number;
  derrota: number;
  empate: number;
  golsContra: number;
  golsPro: number;
}

const ClassificacoesJogo: React.FC = () => {
  const [classificacoes, setClassificacoes] = useState<Classificacao[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    httpEuro
      .get("/euro/api/classificacao/2")
      .then((response) => {
        setClassificacoes(response.data.conteudo);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao carregar classificações:", error);
        setLoading(false);
      });
  }, []);

  const getColorFromTeamName = (teamName: string): string => {
    const lowerCaseTeamName = teamName.toLowerCase();
    if (lowerCaseTeamName.includes("amarelo")) {
      return "#FFFF00";  
    } else if (lowerCaseTeamName.includes("verde")) {
      return "#008000";  
    } else if (lowerCaseTeamName.includes("branco")) {
      return "#FFFFFF";  
    } else if (lowerCaseTeamName.includes("vermelho")) {
      return "#FF0000";  
    } else if (lowerCaseTeamName.includes("azul")) {
      return "#0000FF";  
    } else {
      return "#000000";  
    }
  };
  

  return (
    <Flex
      flex="1"
      flexDirection="column"
      alignItems="center"
      overflowX="auto"
      marginX="auto"
      maxHeight="calc(100vh - 14rem)"
      overflowY="auto"
    >
      <Heading
        fontSize={["xl", "2xl", "3xl"]}
        mt={["5%", "3%", "2%"]}
        mb={["5%", "3%", "2%"]}
      >
        Classificação Olympique
      </Heading>
      <TableContainer
        overflowX="auto"
        marginX="auto"
        maxHeight="calc(100vh - 14rem)"
        overflowY="auto"
      >
        <ChakraUITable>
          <Thead>
            <Tr>
              <Th textAlign="center"></Th>
              <Th textAlign="center">Time</Th>
              <Th>Jogos</Th>
              <Th>Pontos</Th>
              <Th>Vitórias</Th>
              <Th>Derrotas</Th>
              <Th>Empates</Th>
              <Th>Gols Contra</Th>
              <Th>Gols Pró</Th>
              <Th>Saldo Gols</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <Tr>
                <Td colSpan={9} textAlign="center">
                  Carregando...
                </Td>
              </Tr>
            ) : (
              classificacoes.map((classificacao, index) => (
                <Tr key={index}>
                  <Td
                    textAlign="center"
                     width="10px"
                    p="1px"
                  >
                    {index + 1}
                  </Td>
                  <Td>
                  <Flex alignItems="center">
                      <div
                        style={{
                          borderRadius:"50%",
                          width: "40px",
                          height: "40px",
                          marginRight: "10px",
                          backgroundColor: getColorFromTeamName(classificacao.timesResponse.nomeTime),
                        }}
                      />
                      <Text>{classificacao.timesResponse.nomeTime}</Text>
                    </Flex>
                  </Td>
                  <Td textAlign="center">{classificacao.qtdJogos}</Td>
                  <Td textAlign="center">{classificacao.pontos}</Td>
                  <Td textAlign="center">{classificacao.vitoria}</Td>
                  <Td textAlign="center">{classificacao.derrota}</Td>
                  <Td textAlign="center">{classificacao.empate}</Td>
                  <Td textAlign="center">{classificacao.golsContra}</Td>
                  <Td textAlign="center">{classificacao.golsPro}</Td>
                  <Td textAlign="center">{classificacao.golsPro - classificacao.golsContra}</Td>
                </Tr>
              ))
            )}
          </Tbody>
        </ChakraUITable>
      </TableContainer>
    </Flex>
  );
};

export default ClassificacoesJogo;
