import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Flex,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Image,
} from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import { secureHttp, getToken, setAuthToken } from "../services/http";

interface PlayerData {
  idAvaliado: number;
  mediaEstrelas: number;
  quantidadeAvaliacoes: number;
  img: string;
  nome: string;
  urlImagem: string;
}

export const Voting = () => {
  const [playerData, setPlayerData] = useState<PlayerData[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = await getToken(); 
      setAuthToken(token); 

      const response = await secureHttp.get("/avaliacoes-atletas-torneio/medias");
      setPlayerData(response.data.conteudo);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  const renderStars = (mediaEstrelas: number) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <StarIcon
          key={i}
          mr={2}
          boxSize={6}
          color={i < Math.floor(mediaEstrelas) ? "yellow.400" : "#737478"}
        />
      );
    }
    return stars;
  };

  const handleImageClick = (url: string) => {
    setSelectedImage(url);
  };

  return (
    <Box
      overflowY="hidden"
      overflowX="hidden"
      mx="auto"
      mb={10}
      mt={10}
      p={8}
      borderRadius="8px"
      bg="#2D3748"
      width="100%"
      height="full"
      maxWidth={{ base: "400px", md: "60%", lg: "50%", xl: "500px" }}
    >
      <Heading as="h1" size="xl" mb={6} textAlign="center" color="white">
        Votação geral
      </Heading>
      {playerData.length === 0 ? (
        <Text color="white" textAlign="center" fontWeight="bold" fontSize="lg">
          Ainda não há votações.
        </Text>
      ) : (
        playerData.map((player) => (
          <Box
            key={player.idAvaliado}
            bg="white"
            borderRadius="8px"
            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            mt={6}
            mb={6}
          >
            <Flex direction={{ base: "column", md: "row" }} align="center">
              <Avatar
                src={player.urlImagem}  
                borderRadius="full"
                boxSize={{ base: "80px", md: "120px" }}
                mt={4}
                mb={4}
                ml={3}
                onClick={() => handleImageClick(player.urlImagem)}
                cursor="pointer"
              />
              <Flex
                flex={1}
                flexDirection="column"
                ml={{ base: 0, md: 4 }}
                mt={{ base: 4, md: 0 }}
              >
                <Text
                  mb={2}
                  fontWeight="bold"
                  textAlign={{ base: "center", md: "left" }}
                >
                  Nome: {player.nome}
                </Text>
                <Flex
                  mb={2}
                  align="center"
                  justify={{ base: "center", md: "flex-start" }}
                >
                  {renderStars(player.mediaEstrelas)}
                </Flex>
                <Text mb={2} textAlign={{ base: "center", md: "left" }}>
                  Total de votos: <strong>{player.quantidadeAvaliacoes}</strong>{" "}
                </Text>
              </Flex>
            </Flex>
          </Box>
        ))
      )}

      {selectedImage && (
        <Modal isOpen={true} onClose={() => setSelectedImage(null)}>
          <ModalOverlay />
          <ModalContent maxW="800px">
            <ModalHeader textAlign="center">Imagem do Atleta</ModalHeader>
            <ModalCloseButton />
            <ModalBody display="flex" justifyContent="center">
              <Image src={selectedImage} maxW="100%" maxH="500px" />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" onClick={() => setSelectedImage(null)}>
                Fechar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};
