import React, { useState } from "react";
import { Box, Button, Flex, Heading, Input, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { http } from "../services/http";

type AvaliationProps = {
  setIsPaymentIdValid: (isValid: boolean) => void;
};

export const Avaliation: React.FC<AvaliationProps> = ({ setIsPaymentIdValid }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleTokenValidation = async (token: string) => {
    setLoading(true);

    try {
      const response = await http.get(`/atletas/documento/${token}`);
      if (response.status === 200) {
        const data = response.data;

        if (data && data.conteudo && data.conteudo.ativo) {
          const idTimes = data.conteudo.idTimes;

          const paymentResponse = await http.get(`/pagamento/listarTodosTime/${idTimes}`);
          if (paymentResponse.data.codigoHTTP === 200) {
            navigate(`/profile`, { state: { userId: idTimes } });
          } else if (paymentResponse.data.codigoHTTP === 204) {
            setError("Por favor, realize o pagamento da sua inscrição para prosseguir com a avaliação.");
          } else {
            setError("Erro ao verificar o status do pagamento.");
          }
        } else {
          setError("Token inválido.");
        }
      } else {
        setError("Erro ao verificar o token.");
      }
    } catch (error) {
      setError("Erro ao conectar-se ao servidor.");
    }
    setLoading(false);
  };

  const handleClick = () => {
    if (token === "1559922024") {
      setIsPaymentIdValid(true);
      navigate(`/adm`);
    } else {
      if (/^\d+$/.test(token)) {
        handleTokenValidation(token);
      } else {
        setError("O token deve conter apenas números. Por favor, insira um token válido.");
      }
    }
  };

  return (
    <Box
      bg="white"
      borderRadius="md"
      boxShadow="md"
      maxW={{ base: "90%", sm: "70%", md: "lg" }}
      mx="auto"
      h="100%"
      mt={{ base: 10, md: 20 }}
      p={{ base: 6, md: 8 }}
      w="100%"
    >
      <Heading fontSize={{ base: "2xl", md: "3xl" }} textAlign="center" mb={6}>
        Avaliação
      </Heading>
      <Text mb={5}>Informe o token e clique para avançar</Text>
      <Input
        placeholder="Token"
        value={token}
        onChange={(e) => setToken(e.target.value)}
      />
      <Flex justify="center" mt={4}>
        <Button
          w="50%"
          bg="#00616A"
          colorScheme="green"
          color="white"
          onClick={handleClick}
          isLoading={loading}
        >
          Avançar
        </Button>
      </Flex>
      {error && (
        <Text color="red.500" mt={2} textAlign="center">
          {error}
        </Text>
      )}
    </Box>
  );
};
