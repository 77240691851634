import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Input,
  Heading,
  Flex,
  List,
  ListItem,
  Text,
  HStack,
  IconButton,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { PDFDocument, rgb } from "pdf-lib";
import img1 from "../assets/a2.jpeg";
import { IoIosArrowBack } from "react-icons/io";
import { getToken, secureHttp, setAuthToken } from "../services/http";

interface Jogador {
  idAvaliado: number;
  nome: string;
  nota: number;
  quantidadeAvaliacoes: number;
  idade: number;
  urlImagem: string;
}

interface Time {
  nome: string;
  jogadores: Jogador[];
}

async function enviarUsuarioParaAPI(nomeTime: string): Promise<boolean> {
  try {
    const response = await secureHttp.post("/usuario", {
      usuLogin: nomeTime,
      senha: "string",
      nomeTime: nomeTime,
      txEmailResp: "string",
      docCpfResp: 0,
      telResp: "string",
    });
    const data = response.data;
    if (response.status === 200 && data.codigoHTTP === 200) {
      return true;
    } else {
      console.error(
        "Erro ao enviar usuário para a API:",
        data.mensagens[0]?.mensagem || "Erro desconhecido"
      );
      return false;
    }
  } catch (error) {
    console.error("Erro ao enviar usuário para a API:", error);
    return false;
  }
}

async function enviarTimeParaAPI(nomeTime: string): Promise<void> {
  try {
    const response = await secureHttp.post("/times", {
      nomeTime: nomeTime,
      documento: "null",
      imagem: "null",
    });
    const data = response.data;

    if (
      data &&
      data.codigoHTTP !== 0 &&
      data.mensagens &&
      data.mensagens.length > 0
    ) {
      console.error(
        "Erro ao enviar time para a API:",
        data.mensagens[0].mensagem || "Erro desconhecido"
      );
    }
  } catch (error) {
    console.error("Erro ao enviar time para a API:", error);
  }
}

async function fetchIdadeJogador(id: number): Promise<number | undefined> {
  try {
    const response = await secureHttp.get(`/inscricao/${id}`);
    const data = response.data;
    if (data.codigoHTTP === 200 && data.conteudo) {
      return data.conteudo.idade;
    }
  } catch (error) {
    console.error("Erro ao buscar idade do jogador:", error);
  }
}

async function gerarPDFSorteio(jogadores: Jogador[], times: Time[]) {
  const pdfDoc = await PDFDocument.create();
  const font = await pdfDoc.embedFont("Helvetica");

  const adicionarPagina = () => {
    const page = pdfDoc.addPage([595, 842]);
    return { page, yOffset: page.getSize().height - 100 };
  };

  let { page, yOffset } = adicionarPagina();
  const fontSize = 15;

  page.drawText("Sorteio de Times", {
    x: 50,
    y: page.getSize().height - 50,
    size: fontSize + 10,
    font: font,
    color: rgb(0, 0, 0),
  });

  for (const time of times) {
    if (yOffset < 100) {
      const novaPagina = adicionarPagina();
      page = novaPagina.page;
      yOffset = novaPagina.yOffset;
    }

    page.drawText(`Time: ${time.nome}`, {
      x: 50,
      y: yOffset,
      size: fontSize + 5,
      font: font,
      color: rgb(0, 0, 0),
    });

    yOffset -= 20;

    for (const jogador of time.jogadores) {
      if (yOffset < 20) {
        const novaPagina = adicionarPagina();
        page = novaPagina.page;
        yOffset = novaPagina.yOffset;
      }

      page.drawText(`Jogador: ${jogador.nome}`, {
        x: 50,
        y: yOffset,
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
      });

      yOffset -= 20;
    }

    yOffset -= 20;
  }

  const pdfBytes = await pdfDoc.save();
  const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
  const pdfUrl = URL.createObjectURL(pdfBlob);
  window.open(pdfUrl);
}

interface StarRatingProps {
  value: number;
}

const StarRating: React.FC<StarRatingProps> = ({ value }) => {
  const stars = [1, 2, 3, 4, 5];

  return (
    <HStack spacing={1}>
      {stars.map((star) => (
        <Icon
          as={FaStar}
          key={star}
          boxSize={5}
          color={star <= value ? "yellow.400" : "gray.200"}
        />
      ))}
    </HStack>
  );
};

const SorteioPage: React.FC = () => {
  const [quantidadeTimes, setQuantidadeTimes] = useState(0);
  const [jogadores, setJogadores] = useState<Jogador[]>([]);
  const [times, setTimes] = useState<Time[]>([]);
  const [nomesTimes, setNomesTimes] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const fetchJogadores = async () => {
      try {
        const token = await getToken();
        setAuthToken(token);

        const response = await secureHttp.get(
          "/avaliacoes-atletas-torneio/sorteio"
        );
        const data = response.data;

        if (data.codigoHTTP === 200 && data.conteudo) {
          const jogadores = data.conteudo.map(async (jogador: any) => {
            const idade = await fetchIdadeJogador(jogador.idAvaliado);
            return {
              idAvaliado: jogador.idAvaliado,
              nome: jogador.nome,
              nota: jogador.mediaEstrelas,
              quantidadeAvaliacoes: jogador.quantidadeAvaliacoes,
              idade: idade || 0,
              urlImagem: jogador.urlImagem,
            };
          });
          Promise.all(jogadores).then((jogadoresCompleto) => {
            setJogadores(jogadoresCompleto);
          });
        }
      } catch (error) {
        console.error("Erro ao buscar jogadores:", error);
      }
    };
    fetchJogadores();
  }, []);

  const realizarSorteio = () => {
    if (jogadores.length <= 0 || quantidadeTimes <= 0) {
      alert("Informe a quantidade de times.");
      return;
    }

    setModalOpen(true);
  };

  const handleModalSubmit = () => {
    sortearTimes(nomesTimes);
    closeModal();
    setQuantidadeTimes(0);
  };

  const handleNomeTimeChange = (index: number, nome: string) => {
    const newNomes = [...nomesTimes];
    newNomes[index] = nome;
    setNomesTimes(newNomes);
  };

  const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  const sortearTimes = (nomes: string[]) => {
    let jogadoresOrdenados = [...jogadores];
    shuffleArray(jogadoresOrdenados);
    jogadoresOrdenados.sort((a, b) => b.nota - a.nota);

    const novosTimes: Time[] = [];
    const jogadoresPorTime = Math.floor(jogadores.length / quantidadeTimes);
    const jogadoresRestantes = jogadores.length % quantidadeTimes;

    for (let i = 0; i < quantidadeTimes; i++) {
      const time: Time = {
        nome: nomes[i] || `Time ${i + 1}`,
        jogadores: [],
      };
      novosTimes.push(time);
    }

    // // Garantir que o jogador com id 33 esteja no último time
    // const jogadorId33 = jogadoresOrdenados.find(jogador => jogador.idAvaliado === 33);
    // if (jogadorId33) {
    //   const index = jogadoresOrdenados.indexOf(jogadorId33);
    //   jogadoresOrdenados.splice(index, 1); // Remove o jogador do array
    //   novosTimes[novosTimes.length - 1].jogadores.push(jogadorId33); // Adiciona ao último time
    // }

    // Distribuir os jogadores restantes
    let currentIndex = 0;
    jogadoresOrdenados.forEach((jogador) => {
      if (jogador.idAvaliado !== 0) {
        const timeAtual = novosTimes[currentIndex];
        timeAtual.jogadores.push(jogador);
        currentIndex = (currentIndex + 1) % quantidadeTimes;
      }
    });

    setTimes(novosTimes);

    novosTimes.forEach(async (time) => {
      const usuarioCriado = await enviarUsuarioParaAPI(time.nome);
      if (usuarioCriado) {
        await enviarTimeParaAPI(time.nome);
      }
    });

    setTimes(novosTimes);
  };

  const closeModal = () => {
    setModalOpen(false);
    setNomesTimes([]);
  };

  return (
    <Center
      bgImage={`url(${img1})`}
      bgSize="cover"
      bgPosition="center"
      flex="1"
    >
      <Box
        w={["100%", "80%", "60%", "40%"]}
        p={4}
        mt={4}
        borderWidth={1}
        borderRadius="lg"
        boxShadow="lg"
        mx="auto"
        backgroundColor="white"
        position="relative"
        maxHeight="80vh"
        overflowY="auto"
      >
        <IconButton
          mt={{ base: "0%", lg: "3%" }}
          colorScheme="green"
          icon={<IoIosArrowBack />}
          isRound
          position="absolute"
          top="1rem"
          left="1rem"
          onClick={() => window.history.back()}
          aria-label={""}
        />
        <Heading
          mt="3%"
          ml="20px"
          size={{ base: "md", lg: "lg" }}
          textAlign="center"
          mb={4}
        >
          Sorteio de Times - Olympique
        </Heading>
        <HStack mb={4} w="80%">
          <Box mr={5}>
            <label>Quantidade Total de Jogadores:</label>
            <Input type="text" value={jogadores.length} isReadOnly />
          </Box>

          <Box>
            <label>Quantidade de Times:</label>
            <Input
              type="number"
              value={quantidadeTimes}
              onChange={(e) => setQuantidadeTimes(parseInt(e.target.value))}
            />
          </Box>
        </HStack>
        {jogadores.map((jogador, index) => (
          <React.Fragment key={index}>
            <Flex alignItems="center" mb={2} direction={["column", "row"]}>
              <Box mb={[2, 0]}>
                <label>Nome do Jogador:</label>
                <Input type="text" value={jogador.nome} isReadOnly />
              </Box>
              <HStack spacing={4} ml={[0, 4]}>
                <Box>
                  <label>Idade:</label>
                  <Input type="text" value={jogador.idade} isReadOnly />
                </Box>
                <Box>
                  <label>Nota:</label>
                  <StarRating value={jogador.nota} />
                </Box>
              </HStack>
            </Flex>
            <hr
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                borderColor: " #cfcbcb",
              }}
            />
          </React.Fragment>
        ))}
        <List mt={4} spacing={4}>
          {times.map((time, index) => (
            <ListItem
              key={index}
              p={2}
              borderWidth={1}
              borderRadius="md"
              borderColor="gray.300"
              position="relative"
            >
              <Box borderWidth="1px" borderRadius="md" p={4}>
                <Text
                  color="#0C5516"
                  fontWeight="bold"
                  fontSize="2xl"
                  mb={2}
                  textAlign="center"
                >
                  {time.nome}
                </Text>
                <List spacing={2}>
                  {time.jogadores.map((jogador, jIndex) => (
                    <ListItem key={jIndex}>
                      <Text>
                        <strong>Jogador:</strong> {jogador.nome}
                      </Text>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </ListItem>
          ))}
        </List>
        <Box mt="3%">
          <Button colorScheme="blue" onClick={realizarSorteio} margin={2}>
            Sortear
          </Button>

          <Button
            colorScheme="blue"
            onClick={() => gerarPDFSorteio(jogadores, times)}
            margin={2}
          >
            Gerar PDF
          </Button>
          <NavLink to="/tabelaGerada">
            <Button colorScheme="blue" margin={2}>
              Tabela
            </Button>
          </NavLink>
        </Box>

        <Modal isOpen={modalOpen} onClose={closeModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Insira os nomes dos times</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {Array.from({ length: quantidadeTimes }, (_, index) => (
                <Input
                  key={index}
                  placeholder={`Nome do Time ${index + 1}`}
                  mb={2}
                  onChange={(e) => handleNomeTimeChange(index, e.target.value)}
                />
              ))}
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleModalSubmit}>
                Confirmar
              </Button>
              <Button onClick={closeModal}>Cancelar</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Center>
  );
};

export default SorteioPage;
